import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

class Footer extends Component {
  render () {
    return (
        <footer>
          <Box sx={{marginTop: '5rem', marginBottom: '2rem'}}>
            <Grid container justifyContent="center" alignItems="center" spacing={{ xs: 2, md: 3 }}>
              <Grid item>
                <Link href={'/terms'} sx={{textDecoration: 'none'}}>Terms of Use</Link>
              </Grid>
              <Grid item>
                <Link href={'/privacy'} sx={{textDecoration: 'none'}}>Privacy Policy</Link>
              </Grid>
            </Grid>
          </Box>
        </footer>
    );
  }
}

export default Footer;