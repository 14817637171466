import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import Comment from './Comment';
import Logo from './Logo'
import Footer from './Footer'

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
    };
  }

  fetchComments(foreign_id) {
    fetch('https://api.realteasociety.cloud/comments?time=' + Math.floor(Date.now()/60000))
    .then((response) => response.json())
    .then(comments => {
        this.setState({ comments: comments });
    });
  }

  componentDidMount() {
    this.fetchComments()
  }

  render () {
    return (
      <div className="home">
        <Logo />

        <Grid container justifyContent="center" alignItems="center" spacing={{ xs: 2, md: 3 }}>
          {this.state.comments.map(comment => (<Grid item sx={{width: '40em'}}><Comment key={comment.uuid} object={comment}/></Grid>))} 
        </Grid>

        <Footer />
      </div>
    );
  }
}

export default Home;