import React, { Component } from 'react';
import { Typography } from '@mui/material';
import Link from '@mui/material/Link';

class Logo extends Component {
  render () {
    return (
        <header>
            <Link href={`/`} sx={{textDecoration: 'none', color: 'inherit'}}>
              <Typography variant="h1" sx={{fontSize: {xs: 40, md: 80}}}>☕ realtea society</Typography>
              <Typography variant="h5">a community of individuals who want to discuss and share their real estate finds.</Typography>
            </Link>
        </header>
    );
  }
}

export default Logo;