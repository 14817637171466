import React, { Component } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

class Comment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            meta: {
                preview_image: "https://placehold.co/150x150",
                address: "",
                canonical_url: "https://www.zillow.com//" + this.props.object.foreign_id
            },
        };
    }  

    componentDidMount() {
        this.fetchMeta()
    }

    fetchMeta() {
        fetch('https://api.realteasociety.cloud/homes/' + this.props.object.foreign_id)
        .then((response) => response.json())
        .then(meta => {
            this.setState({ meta: meta });
        });
      }

    render () {
        return (
            <Link href={ this.state.meta.canonical_url} target="_blank" rel="noopener noreferrer" sx={{textDecoration: 'none'}}>
                <Card sx={{ display: 'flex' }}>
                    <CardMedia component="img" sx={{ width: 151, objectFit: "cover" }} image={ this.state.meta.preview_image } alt={ this.state.meta.address + " preview thumbnail" } />
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{overflow: 'auto', maxHeight: '75px'}}>
                            <Typography gutterBottom>{ this.state.meta.address }</Typography>
                            <Typography sx={{fontWeight: '100'}}>{this.props.object.content}</Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small">{this.props.object.username}</Button>
                            <Typography variant="caption">{new Date(this.props.object.created_at).toDateString()}</Typography>
                        </CardActions>
                    </Box>
                </Card>
            </Link>
        );
    }
}

export default Comment;